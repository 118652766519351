<template>
  <div class="page-common page-staff-manage">
    <div class="page-params">
      <el-input
        clearable
        class="param-item"
        placeholder="搜邮箱"
        @clear="resetTable"
        v-model="params.email"
      ></el-input>
      <el-button class="param-item" type="primary" icon="el-icon-search" @click="resetTable">搜索</el-button>
      <el-button
        type="primary"
        class="param-item"
        icon="el-icon-plus"
        @click="addModal.visible = true"
      >添加</el-button>
    </div>
    <el-table
      stripe
      ref="table"
      size="medium"
      v-loading="!params.cursor && nextLoading"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
    >
      <el-table-column align="center" label="ID" prop="user_id"></el-table-column>
      <el-table-column align="center" label="用户名" prop="display_name"></el-table-column>
      <el-table-column align="center" label="邮箱" prop="email"></el-table-column>
      <el-table-column align="center" label="状态">
        <template #default="{row}">
          <el-tag type="success" v-if="!row.is_deleted">正常</el-tag>
          <el-tag type="danger" v-else>权限已禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <i class="el-icon-edit" @click="updateTableRow(row)"></i>&nbsp;&nbsp;
          <el-popconfirm
            v-if="row.is_deleted"
            @confirm="recoverTableRow(row)"
            title="确认恢复该用户的所有权限？"
          >
            <template #reference>
              <i style="color: #e50" class="el-icon-refresh-left"></i>
            </template>
          </el-popconfirm>
          <el-popconfirm v-else @confirm="deleteTableRow(row)" title="确认禁用该用户的所有权限？">
            <template #reference>
              <i style="color: #f33" class="el-icon-delete"></i>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="next-page-button">
      <el-button
        size="medium"
        v-if="next_cursor"
        @click="initTableData"
        :loading="nextLoading"
      >点击加载更多...</el-button>
    </div>
    <el-dialog
      center
      title="编辑授权"
      custom-class="large-modal"
      v-model="authModal.visible"
      @close="authModal.visible = false"
      :close-on-click-modal="false"
    >
      <div>
        <div class="auth-desc-head">
          <span class="desc-head-var">权限名称</span>
          <span style="flex-basis: 300px">路由</span>
          <span>状态</span>
          <span>操作</span>
        </div>
        <el-tree
          :data="authListTree"
          :props="defaultProps"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
        >
          <template #default="{ data }">
            <div class="custom-tree-node">
              <span class="tree-node-var">{{data.name}}</span>&nbsp;&nbsp;
              <span class="tree-node-col" style="flex-basis: 300px">{{data.route}}</span>
              <span class="tree-node-col">
                <el-tag v-if="data.is_deleted" type="danger">无效</el-tag>
                <el-tag v-else type="success">生效</el-tag>
              </span>
              <span class="tree-node-col">
                <el-button
                  type="danger"
                  size="mini"
                  v-if="tempPermissions[data.id]"
                  @click="updateUserAuth(data, 'sub')"
                >取消授权</el-button>
                <el-button
                  type="primary"
                  size="mini"
                  v-else
                  @click="updateUserAuth(data, 'add')"
                >添加授权</el-button>
              </span>
            </div>
          </template>
        </el-tree>
      </div>
      <template #footer>
        <el-button type="primary" @click="authModalConfirm">保 存 授 权</el-button>
      </template>
    </el-dialog>
    <el-dialog
      title="添加员工"
      custom-class="medium-modal"
      v-model="addModal.visible"
      @close="addModal.visible = false"
      :close-on-click-modal="false"
    >
      <StaffSelect style="width: 100%" placeholder="搜索内部员工(姓名、邮箱)" v-model="addModal.user_id" />
      <template #footer>
        <el-button
          :disabled="addModal.user_id === ''"
          type="primary"
          @click="addModalConfirm"
        >添加到权限管理员工池</el-button>
      </template>
    </el-dialog>
    <PageOfTop />
  </div>
</template>
<script>
import { nextTick } from 'vue'
import PageOfTop from '@/components/base/page-of-top'
import StaffSelect from '@/components/use-case/staff-solo-select'
import {
  getAdminStaffList,
  addAdminStaff,
  deleteAdminStaff,
  recoverAdminStaff,
  getAdminAuthList,
  getPermissionListByUserId,
  updateStaffAuth
} from '@/domains/base-baoer'

function fixScrollHeight() {
  const height = document.documentElement.scrollTop
  nextTick(() => {
    window.scrollTo(0, height)
  })
}

function checkManagerForSomeAuth(auth, my_user_id) {
  if (!auth.managers || !auth.managers.length) return true
  if (auth.managers.find((v) => v.user_id === Number(my_user_id))) return true
  return false
}

export default {
  name: 'StaffManage',
  components: { PageOfTop, StaffSelect },
  data() {
    return {
      params: {
        limit: 30,
        permission_id: '',
        user_id: '',
        display_name: '',
        email: '',
        cursor: ''
      },
      tableData: [],
      commonModal: {
        visible: false,
        editingRow: null
      },
      next_cursor: '',
      nextLoading: false,
      addModal: {
        visible: false,
        user_id: ''
      },
      // 编辑授权
      authListTree: [],
      defaultProps: {
        children: 'items',
        label: 'name'
      },
      authModal: {
        visible: false,
        user_id: '',
        permissions: []
      },
      tempPermissions: {}
    }
  },
  computed: {
    my_user_id() {
      return this.$store.state.user.userInfo.uid
    }
  },
  mounted() {
    this.initTableData()
    this.initAuthList()
  },
  methods: {
    resetTable() {
      this.next_cursor = ''
      this.initTableData()
    },
    initTableData() {
      const { params } = this
      params.cursor = this.next_cursor
      this.nextLoading = true
      getAdminStaffList(params)
        .then((data) => {
          this.next_cursor = data.next_cursor
          if (data.items && data.items.length) {
            if (params.cursor) {
              this.tableData.push(...data.items)
            } else {
              this.tableData = data.items
            }
          }
          if (params.cursor) fixScrollHeight()
          if (params.cursor && !data.next_cursor) {
            this.$message({
              type: 'warning',
              message: '已加载完毕'
            })
          }
        })
        .finally(() => {
          this.nextLoading = false
        })
    },
    initAuthList() {
      getAdminAuthList().then((data) => {
        if (data.items && data.items.length) {
          this.authListTree = data.items
        } else {
          this.authListTree = []
        }
      })
    },
    updateTableRow(row) {
      console.log('更新', row)
      Object.assign(this.authModal, {
        visible: true,
        user_id: row.user_id,
        permissions: []
      })
      this.tempPermissions = {}
      getPermissionListByUserId(row.user_id).then((data) => {
        if (data.items && data.items.length) {
          const temp = {}
          data.items.forEach((v) => {
            temp[v.id] = true
          })
          this.tempPermissions = temp
          this.authModal.permissions = data.items.map((v) => v.id)
        }
      })
    },
    updateUserAuth(data, type) {
      // console.log(data, this.my_user_id)
      if (!checkManagerForSomeAuth(data, this.my_user_id)) {
        this.$message.error('您不是该权限的管理员')
        return
      }
      if (type === 'add') {
        this.tempPermissions = {
          ...this.tempPermissions,
          [data.id]: true
        }
        this.authModal.permissions.push(data.id)
      }
      if (type === 'sub') {
        this.tempPermissions[data.id] = false
        this.authModal.permissions = this.authModal.permissions.filter((v) => v !== data.id)
      }
    },
    authModalConfirm() {
      const { permissions, user_id } = this.authModal
      const body = {
        permission_ids: permissions,
        user_id
      }
      updateStaffAuth(body)
        .then(() => {
          this.$message.success('已保存')
          this.authModal.visible = false
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    addModalConfirm() {
      console.log('add-staff', this.addModal.user_id)
      addAdminStaff({ user_id: this.addModal.user_id })
        .then(() => {
          this.$message.success('已添加')
          this.addModal.visible = false
          this.resetTable()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    deleteTableRow(row) {
      console.log('delete', row.user_id)
      deleteAdminStaff({ user_id: row.user_id })
        .then(() => {
          this.$message.success('已禁用')
          this.resetTable()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    recoverTableRow(row) {
      console.log('delete', row.user_id)
      recoverAdminStaff({ user_id: row.user_id })
        .then(() => {
          this.$message.success('已恢复')
          this.resetTable()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    }
  }
}
</script>
<style lang="scss">
.page-staff-manage {
  position: relative;
  width: calc(100% - 6px);
  .el-table {
    overflow: visible;
    .el-table__header-wrapper {
      position: sticky;
      top: 0;
      z-index: 9;
    }
    i[class^='el-icon'] {
      cursor: pointer;
      font-size: 18px;
    }
    .el-tag {
      margin-right: 4px;
    }
  }
  .next-page-button {
    color: #e6a23c;
    cursor: pointer;
    padding: 24px;
    padding-bottom: 48px;
    text-align: center;
    width: 100%;
  }
  .el-tree {
    background: #f4f4f4;
    padding: 8px;
    .el-tree-node__content {
      height: 36px;
      &:hover {
        background: #fff;
      }
    }
  }
  .custom-tree-node {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: space-between;
    padding-right: 8px;
    width: 100%;
    .tree-node-col {
      flex-basis: 120px;
      text-align: left;
    }
    .tree-node-var {
      color: #333;
      flex-grow: 1;
      flex-shrink: 0;
      font-weight: 600;
      margin-right: auto;
    }
  }
  .auth-desc-head {
    align-items: center;
    background: #f4f4f4;
    border-bottom: 1px solid #fff;
    color: #999;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 36px;
    justify-content: space-between;
    padding: 0 16px 0 40px;
    width: 100%;
    span {
      flex-basis: 120px;
      flex-grow: 0;
      text-align: left;
      &.desc-head-var {
        flex-grow: 1;
        text-align: left;
      }
    }
  }
}
</style>
