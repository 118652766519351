<template>
  <div class="page-of-top" @click="handlePageOfTop">
    <i class="el-icon-caret-top arrow"></i>
  </div>
</template>

<script>
export default {
  name: 'PageOfTop',
  methods: {
    handlePageOfTop() {
      window.scroll({ left: 0, top: 0 })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-of-top {
  background-color: #58b7ff;
  border-radius: 25px;
  bottom: 100px;
  cursor: pointer;
  height: 50px;
  opacity: 0.3;
  position: fixed;
  right: 100px;
  transition: 0.3s;
  width: 50px;
  z-index: 9;
  &:hover {
    opacity: 1;
  }
}
.arrow {
  color: #fff;
  display: block;
  font-size: 22px;
  line-height: 50px;
  margin: auto;
  text-align: center;
}
</style>
